import React from "react"
import CaseStudiesImagesStyle from "./CaseStudiesImagesStyle.module.styl"
import { Link } from "gatsby"
import Image from "gatsby-image"

const CaseStudiesImages = ({
  caseStudyImage1,
  caseStudyImage2,
  caseStudyImage3,
  caseStudyImage4,
  caseStudyImage5,
  caseStudyImage6,
  caseStudyImage7,
  caseStudyImage8,
  caseStudyImage9,
  caseStudyImage10,
  caseStudyImage11,
  caseStudyImage12,
  caseStudyImage13,
  caseStudyImage14,
  caseStudyImage15,
}) => {
  const img1 = caseStudyImage1[0]?.node.childImageSharp.fluid
  const img2 = caseStudyImage2[0]?.node.childImageSharp.fluid
  const img3 = caseStudyImage3[0]?.node.childImageSharp.fluid
  const img4 = caseStudyImage4[0]?.node.childImageSharp.fluid
  const img5 = caseStudyImage5[0]?.node.childImageSharp.fluid
  const img6 = caseStudyImage6[0]?.node.childImageSharp.fluid
  const img7 = caseStudyImage7[0]?.node.childImageSharp.fluid
  const img8 = caseStudyImage8[0]?.node.childImageSharp.fluid
  const img9 = caseStudyImage9[0]?.node.childImageSharp.fluid
  const img10 = caseStudyImage10[0]?.node.childImageSharp.fluid
  const img11 = caseStudyImage11[0]?.node.childImageSharp.fluid
  const img12 = caseStudyImage12[0]?.node.childImageSharp.fluid
  const img13 = caseStudyImage13[0]?.node.childImageSharp.fluid
  const img14 = caseStudyImage14[0]?.node.childImageSharp.fluid
  const img15 =
    caseStudyImage15 && caseStudyImage15[0]?.node?.childImageSharp?.fluid
  return (
    <section className={CaseStudiesImagesStyle.case_studies_images_section}>
      <div className="row no-gutters">
        <div className="col-lg-4 col-sm-6">
          <Link
            to="/case-study-tylor-swift"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img1} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-mtv"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img2} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-rihanna"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img3} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-nordic-naturals"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img4} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-yahoo"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img5} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-mind-valley"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img6} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-crank"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img7} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-strike-social"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img8} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-lala-anthony"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img9} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-katie-couric"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img10} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-michael-breus"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img11} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-one-million-followers"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img12} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-planet-home"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img13} />
          </Link>
        </div>
        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-craig-clemens"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img14} />
          </Link>
        </div>

        <div className="col-lg-4 col-sm-6">
          {" "}
          <Link
            to="/case-study-dr-staci"
            className={CaseStudiesImagesStyle.image_holder}
          >
            <Image className={CaseStudiesImagesStyle.image} fluid={img15} />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default CaseStudiesImages
