import React from "react"
import HeroSection from "./components/heroSection/HeroSection"
import NewsletterSection from "../newsletter/NewsletterSection"
import WorkSection from "./components/workSection/WorkSection"
import CaseStudiesImages from "./components/caseStudiesImages/CaseStudiesImages"

const CaseStudiesTemplate = ({
  caseStudiesLogo,
  caseStudy1,
  caseStudy2,
  caseStudy3,
  caseStudy4,
  caseStudy5,
  caseStudy6,
  caseStudy7,
  caseStudy8,
  caseStudy9,
  caseStudy10,
  caseStudy11,
  caseStudy12,
  caseStudy13,
  caseStudy14,
  caseStudy15,
}) => {
  return (
    <React.Fragment>
      <HeroSection caseStudiesLogo={caseStudiesLogo} />
      <CaseStudiesImages
        caseStudyImage1={caseStudy1}
        caseStudyImage2={caseStudy2}
        caseStudyImage3={caseStudy3}
        caseStudyImage4={caseStudy4}
        caseStudyImage5={caseStudy5}
        caseStudyImage6={caseStudy6}
        caseStudyImage7={caseStudy7}
        caseStudyImage8={caseStudy8}
        caseStudyImage9={caseStudy9}
        caseStudyImage10={caseStudy10}
        caseStudyImage11={caseStudy11}
        caseStudyImage12={caseStudy12}
        caseStudyImage13={caseStudy13}
        caseStudyImage14={caseStudy14}
        caseStudyImage15={caseStudy15}
      />
      <WorkSection />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudiesTemplate
