import React, { useState } from "react"
import HeroSectionStyle from "./HeroSectionStyle.module.styl"
import Footer from "../../../../common/footer/Footer"

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
}

function HeroSection({ caseStudiesLogo }) {
  const [isVisible, setSectionVisible] = useState(false)

  function onChange() {
    setSectionVisible(!isVisible)
  }
  return (
    <section className={HeroSectionStyle.wrapper}>
      <div className={HeroSectionStyle.case_studies_holder}>
        <h2>
          Case Studies
          <span className={HeroSectionStyle.hr} />
        </h2>

        <div className={HeroSectionStyle.content}>
          {" "}
          Over a decade of successful project executions for global clients
        </div>
      </div>
      <div className={HeroSectionStyle.image_holder}></div>
      <Footer />
    </section>
  )
}

export default HeroSection
